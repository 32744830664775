<template>
  <nav class="header-content__nav2">
    <div class="header-nav__container">

      <!-- Button for the hamburger menu -->
      <button @click="toggleMenu" class="material-symbols-rounded hamburger-button">Menu</button>

      <!-- Mobile hamburger menu -->
      <div :class="['header-content__info--mobile', { 'is-active': isMenuOpen }]">
        <ul class="header-content__info--list">
          <li v-for="(item, index) in menuItems" :key="index" class="header-content__info--list-item">
            <a v-if="isExternalLink(item.route)" :href="item.route" target="_blank" rel="noopener noreferrer">
              <img class="header-content__icon" alt="">{{ item.label }}
            </a>
            <router-link v-else :to="item.route">
              <img class="header-content__icon" alt="">{{ item.label }}
            </router-link>
          </li>
        </ul>
      </div>

      <!-- Desktop Menu -->
      <div class="header-content__info--desktop">
        <ul class="header-content__info--list">
          <li v-for="(item, index) in menuItems" :key="index" class="header-content__info--list-item">
            <a v-if="isExternalLink(item.route)" :href="item.route" target="_blank" rel="noopener noreferrer">
              <img class="header-content__icon" alt="">{{ item.label }}
            </a>
            <router-link v-else :to="item.route">
              <img class="header-content__icon" alt="">{{ item.label }}
            </router-link>
          </li>
        </ul>
      </div>

    </div>
  </nav>
</template>

<script>
export default {
  name: "HeaderNav",
  data() {
    return {
      isMenuOpen: false, // State to control the hamburger menu
      menuItems: [
        { label: "Live Lines", route: "/live-lines" },
        { label: "How does it work?", route: "/how-does-it-work" }
        // { label: "Leaderboard", route: "" },
      ],
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    handleResize() {
      if (window.innerWidth > 599) {
        this.isMenuOpen = false;
      }
    },
    isExternalLink(link) {
      return link.startsWith('http');
    }
  },
  watch: {
    // Watch for route changes to close the menu if the route changes
    $route() {
      this.isMenuOpen = false;
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize(); // Initialize the menu visibility based on the current window size
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>
