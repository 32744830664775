import { createRouter, createWebHistory } from 'vue-router';
import Home from '../pages/home.vue';
import ParticipantGuide from '../pages/ParticipantGuide.vue';
import LiveLines from '../pages/LiveLines.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/how-does-it-work',
    name: 'ParticipantGuide',
    component: ParticipantGuide
  },
  {
    path: '/live-lines',
    name: 'LiveLines',
    component: LiveLines
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
