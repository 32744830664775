<template>
  <Fragment>
    <HeaderComponent />
    <router-view />
  </Fragment>
</template>

<script>
import HeaderComponent from "@/components/header.vue";

export default {
  name: "App",
  components: {
    HeaderComponent,
  },
};
</script>
