<template>
  <div class="live-lines-container">
    <iframe src="https://betslip.nflbetclash.com/mainbk/betslip" width="100%" height="100%"></iframe>
  </div>
</template>

<script>
export default {
  name: "LiveLines",
};
</script>