<template>
  <div class="entry-cost-banner max-container wider">
    <p class="banner-paragraph">NFL Contests:</p>
    <h2 class="banner-title">CONTEST ENTRY COST: $300 USD</h2>
    <div class="price-info">
      <p>1st Place Prize: $10,000</p>
      <p>2nd Place Prize: $3,000</p>
      <p>3rd Place Prize: $1,000</p>
      <p class="restrictions">*Restrictions Apply*</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "EntryCostBanner",
};
</script>