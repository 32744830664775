<template>
  <Fragment>
    <HeroBanner/>
    <StepImages/>
    <EntryCostBanner/>
  </Fragment>
</template>

<script>
  import HeroBanner from "@/components/heroBanner.vue"
  import StepImages from "@/components/StepsGallery.vue"
  import EntryCostBanner from "@/components/InfoContest.vue"

  export default {
    name: "PageHome",
    components: {
      HeroBanner,
      StepImages,
      EntryCostBanner
    },
    methods: {
    openModal() {
      this.$refs.modal.openModal();
      },
    },
  }
</script>