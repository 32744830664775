<template>
  <div class="step-images-container">
    <div v-for="(step, index) in steps" :key="index" class="step-item">
      <div class="step-link" @click="handleStepClick(index)">
        <div class="step-image">
          <div class="step-title">{{ step.title }}</div>
          <img :src="step.imageSrc" :alt="step.title" />
          <p class="step-paragraph">{{ step.paragraph }} </p>
        </div>
      </div>
    </div>
    <ModalComponent ref="modalRef" />
    <LoginModal ref="loginModalRef" />
  </div>
</template>

<script>
import ModalComponent from "@/components/modal.vue";
import LoginModal from "@/components/ModalLogin.vue";

export default {
  name: "StepImages",
  components: {
    ModalComponent,
    LoginModal
  },
  data() {
    return {
      steps: [
        { 
          title: "STEP 1", 
          imageSrc: require("@/assets/images/steps_gallery/user.svg"),
          paragraph: "Create an account",
          action: "modal" // Indicates that the modal should open
        },
        { 
          title: "STEP 2", 
          imageSrc: require("@/assets/images/steps_gallery/deposit.svg"),
          paragraph: "Make a deposit",
          action: "loginModal" // Indicates that the login modal should open
        },
        { 
          title: "STEP 3", 
          imageSrc: require("@/assets/images/steps_gallery/bets.png"),
          paragraph: "Make your bets",
          action: "/live-lines" // Internal route for redirection
        },
      ]
    };
  },
  methods: {
    handleStepClick(index) {
      const step = this.steps[index];
      if (step.action === "modal") {
        this.openModal();
      } else if (step.action === "loginModal") {
        this.openLoginModal();
      } else if (step.action.startsWith("/")) { 
        this.$router.push(step.action); // Navigate to the internal route "/live-lines"
      } else if (step.action.startsWith("http")) {
        window.open(step.action, '_blank'); // Open the URL in a new tab
      }
    },
    openModal() {
      this.$refs.modalRef.openModal();
    },
    openLoginModal() {
      this.$refs.loginModalRef.openModal();
    }
  }
}
</script>