<template>
  <div class="header-img">
    <a href="/">
      <img class="header-content__img" src="@/assets/images/logo.png" alt="nflbetclash" />
    </a>
  </div>
</template>

<script>
export default {
  name: "HeaderLogo",
};
</script>