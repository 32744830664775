<template>
  <div class="banner max-container wider">
    <div class="banner__bg">
      <img :src="banner.bgImage" :alt="banner.alt">
    </div>
    <div class="banner__info wider">
      <div class="banner__text">
        <h1 class="banner__text-title">
          <span v-html="formattedTitle"></span>
        </h1>
        <div class="banner__text-content">
          <p class="banner__text-subtitle">
            <span v-html="formattedSubtitle"></span>
          </p>
          <span class="line"></span>
          <p class="banner__text-subtitle-secondary">
            <span v-html="formattedSubtitleSecondary"></span>
          </p>
        </div>
      </div>
    </div>
    <ModalComponent ref="modal" />
  </div>
</template>

<script>
import ModalComponent from "@/components/modal.vue";

export default {
  name: 'HeroBasic',
  components: {
    ModalComponent,
  },
  methods: {
    openModal() {
      this.$refs.modal.openModal();
    },
  },
  data() {
    return {
      banner: {
        title      :  'NFL REGULAR SEASON BATTLE',
        subtitle   :  '3 CONTESTS FOR UP TO $10,000 EACH in Prizes',
        subtitleSecondary: 'Week 1-9  Week 10-18  PLAYOFFS',
        bgImage    :  require('../assets/images/banner/banner-nflbetclash.jpg'),
        alt        :  'img-banner'
      }
    }
  },
  computed: {
    formattedTitle() {
      return this.banner.title.replace('NFL', '<span class="highlight">NFL</span>')
                              .replace('SEASON', '<span class="highlight">SEASON</span>');
    },
    formattedSubtitle() {
      return this.banner.subtitle.replace('3', '<span class="highlight">3</span>')
                                .replace('$10,000', '<span class="highlight">$10,000</span>');
    },
    formattedSubtitleSecondary() {
      return this.banner.subtitleSecondary.replace('1-9', '<span class="highlight">1-9</span>')
                                          .replace('10-18', '<span class="highlight">10-18</span>');
    }
  }
}
</script>