<template>
  <div class="modal-backdrop modal-login" v-if="showModal">
    <div class="modal-content">
      <div class="modal-backdrop__header">
        <button class="close-button" @click="closeModal">
          <span class="material-symbols-rounded">close</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- Tu formulario de inicio de sesión aquí -->
        <form class="login__form form__desktop" name="LoginForm" action="javascript:void(0)" onsubmit="BackEndLogin(this); return false">
          <input type="hidden" name="siteID" :value="`${DGS_SITEID}`" />
          <input type="hidden" name="errorURL" :value="`//www.${BackEndUrl}/?login-error`" />
  
          <div class="login__form--item">
            <label class="login__form--label" for="username">Username</label>
            <input class="login__form--inputs" name="username" id="username" type="text" required placeholder="Username" />
            
          </div>
  
          <div class="login__form--item">
            <label class="login__form--label" for="password">Password</label>
            <input class="login__form--inputs" type="password" name="password" id="password" required placeholder="Password" />
          </div>
          
          <div class="text-center mt-2"> 
            <span name="msj_loading" id="id-login-loading"></span>
            <small class="text-danger" name="msj_error_lg"></small>
          </div>
  
          <div class="header-buttons">
            <button class="btn-login secondary-button secondary-button__classic" type="submit">Login</button>
          </div>
  
          <input type="hidden" name="BackEndUrl" :value="`https://betslip.${BackEndUrl}`">
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoginModal',
  props: {
    modalRef: Object
  },
  data() {
    return {
      showModal: false,
      BackEndUrl: "nflbetclash.com",
      DGS_SITEID: "1118"
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    }
  }
}
</script>