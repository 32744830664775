<template>
  <div class="header-buttons">
    <form class="login__form form__desktop" name="LoginForm" action="javascript:void(0)" onsubmit="BackEndLogin(this); return false">
      <input type="hidden" name="siteID" :value="`${DGS_SITEID}`" />
      <input type="hidden" name="errorURL" :value="`//www.${BackEndUrl}/?login-error`" />

      <div class="login__form--item">
        <label class="login__form--label" for="username">Username</label>
        <input class="login__form--inputs" name="username" id="username" type="text" required placeholder="Username" />
      </div>

      <div class="login__form--item">
        <label class="login__form--label" for="password">Password</label>
        <input class="login__form--inputs" type="password" name="password" id="password" required placeholder="Password" />
      </div>
      
      <div class="text-center mt-2"> 
        <span name="msj_loading" id="id-login-loading"></span>
        <small class="text-danger" name="msj_error_lg"></small>
        <div class="login__link">
          <a :href="`https://betslip.${BackEndUrl}/home/reset-pass`">Forgot your Password ?</a>
        </div>
      </div>

      <div class="header-buttons">
        <button class="btn-login secondary-button secondary-button__classic" type="submit">Login</button>
        <a href="#" class="btn-login secondary-button secondary-button__betslip" @click="openModal">Join now</a>
      </div>

      <input type="hidden" name="BackEndUrl" :value="`https://betslip.${BackEndUrl}`">
    </form>
  </div>
</template>

<script>
  export default {
    name: 'HeaderLoginForm',
    props: {
      modalRef: Object,
    },
    methods: {
      openModal() {
        this.modalRef.openModal(); // Use the passed modal reference as a prop
      },
    },
    setup:() => {
      const BackEndUrl = "nflbetclash.com";
      const DGS_SITEID = "1118";
      
      return {
        BackEndUrl,
        DGS_SITEID
      }
    }
  }
</script>