<template>
  <header class="header">
    <div class="header-content max-container">
      <HeaderLogo />
      <HeaderLoginForm :modalRef="modalRef" />
    </div>
    <HeaderNav />
    <ModalComponent ref="modalRef" />
  </header>
</template>

<script>
import { ref } from 'vue';
import HeaderLogo from "@/components/headerLogo.vue";
import HeaderLoginForm from "@/components/headerLoginForm.vue";
import HeaderNav from "@/components/headerNav.vue";
import ModalComponent from "@/components/modal.vue";

export default {
  name: "HeaderComponent",
  components: {
    HeaderLogo,
    HeaderLoginForm,
    HeaderNav,
    ModalComponent
  },
  setup() {
    const modalRef = ref(null);

    return {
      modalRef,
    };
  },
};
</script>