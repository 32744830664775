<template>
  <div class="internal-page">
    <h1 class="page-title">ENTRY COST BANNER</h1>

    <section class="content-section">
      <p>Participants need to create an account with <a href="https://www.nflbetclash.com" target="_blank">www.nflbetclash.com</a>.</p>

      <p>Once the account has been created, participants must make a $300 USD deposit via any of the payment methods available in the cashier.</p>

      <p>Once the deposit is received, each participant will receive a total of 50,000 units to place wagers.</p>

      <p>Participants will be able to bet a max of 5000 units per game (main games).</p>

      <p>If a participant is out of funds, he can re-deposit to get more units.</p>

      <p>If a participant still has units available and makes a new deposit, the new credits will not be added to his account until the previous units have been lost.</p>

      <p>There are 3 different contests available:</p>
      <ul>
        <li>NFL REGULAR SEASON WEEK 1 TO WEEK 9</li>
        <li>NFL REGULAR SEASON WEEK 10 TO WEEK 18</li>
        <li>NFL PLAYOFFS WHICH INCLUDE THE SUPER BOWL</li>
      </ul>

      <p>At the end of each contest period, the players with the highest units available will be the winner of the main prize.</p>

      <h2>Prize plan:</h2>
      <ul>
        <li>1st place: A maximum of $10,000 USD or 33% of the total money collected for the contest.</li>
        <li>2nd place: A maximum of $3,000 USD or 10% of the total money collected for the contest.</li>
        <li>3rd place: A maximum of $1,000 USD or 3.5% of the total money collected for the contest.</li>
      </ul>

      <p>The contest prizes will be paid out once each tournament is over.</p>
      <p>The winning players will be enabled withdrawals options in their cashiers.</p>
      <p>The payment method used to receive payouts will be by crypto.</p>
    </section>
  </div>
</template>

<script>
export default {
  name: "InternalPage",
};
</script>