<template>
  <div class="modal-backdrop modal-content-signup" v-if="showModal">
    <div class="modal-content">
      <div class="modal-backdrop__header header-signup">
        <button class="close-button" @click="closeModal">
          <span class="material-symbols-rounded">close</span>
        </button>
      </div>
      <!-- Content of your modal here -->
      <div class="modal-body">
      <iframe src="https://signup.isppro.net/signup?domain=nflbetclash.com&lang=en" width="100%" height="600px" style="border: none;"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ModalComponent',
    data() {
      return {
        showModal: false
      }
    },
    methods: {
      openModal() {
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
      }
    }
  }
</script>